import { DsmButton, DsmGrid, DsmIcon, DsmLoadingIndicator } from "@dsm-dcs/design-system-react";
import { useEffect, useState } from "react";
import PageHeader from "../../../components/pageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import styles from "./SupportForm.module.scss";
import Input from "../../../components/form/input/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getSupportFormFormSchema, SupportFormForm } from "../../../models/forms/support-form.model";
import { getUser } from "../../../services/user.service";
import { useLayout } from "../../../contexts/layout.context";
import { getCountriesWithNameForSelect } from "../../../services/metaData.service";
import { DsmSelectOption } from "@dsm-dcs/design-system";
import Select from "../../../components/form/select/Select";
import { SupportCategory, SupportRegion, SupportSuggestionItem, SupportTechnicalItem } from "../../../models/enums/supportForm.enum";
import TextArea from "../../../components/form/textArea/TextArea";
import CheckBoxGroup from "../../../components/form/checkBoxGroup/CheckBoxGroup";
import { routes, routeTypes } from "../../../routes";

interface MarketoData {
  FirstName: string;
  LastName: string;
  Country: string;
  requestDetails1: string;
  requestDetails2: string;
  requestDetails3?: string;
  requestDetails4?: string;
  requestDetails5: string;
  requestDetails6?: string;
  MktoPersonNotes?: string;
  formID: string;
}

function SupportForm() {
  //Constants
  const munchkinId = "686-TBL-243";
  const supportFormId = 141;

  //Hooks
  const { t } = useTranslation();
  const { setToast, setCrumbs, setPageTitle } = useLayout();
  const [schema] = useState(getSupportFormFormSchema());
  const {
    control,
    formState: { errors },
    handleSubmit,
    resetField,
    setValue,
    watch
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: {
      termsAndConditionsAccepted: false
    }
  });

  //State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [countryOptions, setCountryOptions] = useState<DsmSelectOption[]>([]);
  const [regionOptions, setRegionOptions] = useState<DsmSelectOption[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<DsmSelectOption[]>([]);
  const [technicalItemOptions, setTechnicalItemOptions] = useState<DsmSelectOption[]>([]);
  const [suggestionOptions, setSuggestionOptions] = useState<DsmSelectOption[]>([]);
  const [termsAndConditionsOptions, setTermsAndConditionsOptions] = useState<string[]>([]);
  const category = watch("category");

  //Effects
  useEffect(() => {
    setPageTitle(t("support.page.title"));
    setCrumbs([
      { title: t("support.page.title"), type: routeTypes.support, url: routes.support },
      { title: t("support-form.page.title"), type: routeTypes.supportForm }
    ]);
    const script = document.createElement("script");
    script.src = "https://686-TBL-243.mktoweb.com/js/forms2/js/forms2.min.js";
    script.async = true;
    script.onload = handleOnload;
    document.body.appendChild(script);

    initForm();

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    resetField("technicalItem");
    resetField("suggestion");
    resetField("url");
  }, [category]);

  //Methods
  const handleOnload = () => {
    window.MktoForms2.loadForm(`//${munchkinId}.mktoweb.com`, munchkinId, supportFormId);
  };

  const initForm = async () => {
    initUser();

    const countryOptions = await getCountriesWithNameForSelect(setToast);
    setCountryOptions(countryOptions);

    setRegionOptions(
      Object.keys(SupportRegion).map((region) => {
        return { value: region, text: t(`general.support-region.${region.toLocaleLowerCase()}`) };
      })
    );

    setCategoryOptions(
      Object.keys(SupportCategory).map((category) => {
        return { value: category, text: t(`general.support-category.${category.toLocaleLowerCase()}`) };
      })
    );

    setTechnicalItemOptions(
      Object.keys(SupportTechnicalItem).map((technicalItem) => {
        return { value: technicalItem, text: t(`general.support-technical-item.${technicalItem.toLocaleLowerCase()}`) };
      })
    );

    setSuggestionOptions(
      Object.keys(SupportSuggestionItem).map((suggestion) => {
        return { value: suggestion, text: t(`general.support-suggestion.${suggestion.toLocaleLowerCase()}`) };
      })
    );

    setTermsAndConditionsOptions([t("support-form.terms-and-conditions-label")]);

    setIsLoading(false);
  };

  const initUser = async () => {
    const user = await getUser();
    setValue("firstName", user.firstName);
    setValue("lastName", user.lastName);
    setValue("email", user.email);
  };

  const onSubmit = async (formData: SupportFormForm) => {
    setIsSubmitting(true);
    const form = window.MktoForms2.allForms()[0];

    const data: MarketoData = {
      FirstName: formData.firstName,
      LastName: formData.lastName,
      Country: formData.country,
      requestDetails1: formData.region,
      requestDetails2: formData.category,
      requestDetails3: undefined, //Set later in conditional
      requestDetails4: undefined, //Set later in conditional
      requestDetails5: "VeraxTM DBS Analytics",
      requestDetails6: undefined, //Set later in conditional
      MktoPersonNotes: undefined, //Set later in conditional
      formID: "dsm-firmenich-dcs-internal-support-form"
    };

    if (formData.category === SupportCategory.Functional) {
      data.requestDetails3 = formData.technicalItem;
      data.requestDetails4 = formData.url;
      data.MktoPersonNotes = formData.description;
    } else if (formData.category === SupportCategory.Suggestion) {
      data.requestDetails3 = formData.suggestion;
      data.MktoPersonNotes = formData.description;
    } else if (formData.category === SupportCategory.Feedback) {
      data.requestDetails6 = formData.description;
    } else if (formData.category === SupportCategory.Other) {
      data.MktoPersonNotes = formData.description;
    }
    form.addHiddenFields(data);
    form.onSuccess(() => {
      setIsSuccessful(true);
      return false;
    });
    form.submit();
  };

  return (
    <DsmGrid className="main-container main-container--with-breadcrumb" fixed={true} container-only={true}>
      <PageHeader header={t("support-form.page.title")} description={t("support-form.page.description")}></PageHeader>
      {!isLoading || isSubmitting ? (
        !isSuccessful ? (
          <>
            <div className={styles.form}>
              <Input
                control={control}
                fieldName="firstName"
                label={t("support-form.first-name")}
                fieldError={errors.firstName}
                schema={schema}
              ></Input>
              <Input
                control={control}
                fieldName="lastName"
                label={t("support-form.last-name")}
                fieldError={errors.lastName}
                schema={schema}
              ></Input>
              <Select
                fieldName="country"
                control={control}
                options={countryOptions}
                label={t("support-form.country")}
                fieldError={errors.country}
                schema={schema}
                useSearch={true}
                emptyText={t("general.no-options")}
              ></Select>
              <Input
                control={control}
                fieldName="email"
                type="email"
                label={t("support-form.email")}
                fieldError={errors.email}
                schema={schema}
              ></Input>
              <Select
                fieldName="region"
                control={control}
                options={regionOptions}
                label={t("support-form.region")}
                fieldError={errors.region}
                schema={schema}
                emptyText={t("general.no-options")}
              ></Select>
              <Select
                fieldName="category"
                control={control}
                options={categoryOptions}
                label={t("support-form.category")}
                fieldError={errors.category}
                schema={schema}
                emptyText={t("general.no-options")}
              ></Select>
              {category === SupportCategory.Functional ? (
                <Select
                  fieldName="technicalItem"
                  control={control}
                  options={technicalItemOptions}
                  label={t("support-form.technical-item")}
                  fieldError={errors.technicalItem}
                  forceRequired={true} //Cannot use schema because it is conditional
                  emptyText={t("general.no-options")}
                ></Select>
              ) : null}
              {category === SupportCategory.Suggestion ? (
                <Select
                  fieldName="suggestion"
                  control={control}
                  options={suggestionOptions}
                  label={t("support-form.suggestion")}
                  fieldError={errors.suggestion}
                  forceRequired={true} //Cannot use schema because it is conditional
                  emptyText={t("general.no-options")}
                ></Select>
              ) : null}
              {category ? (
                <TextArea
                  control={control}
                  fieldName="description"
                  label={t(`support-form.description.${category.toLocaleLowerCase()}`)}
                  fieldError={errors.description}
                  schema={schema}
                  rows={6}
                ></TextArea>
              ) : null}
              {category === SupportCategory.Functional ? (
                <Input control={control} fieldName="url" label={t("support-form.url")} fieldError={errors.url} schema={schema}></Input>
              ) : null}
              <CheckBoxGroup
                control={control}
                fieldName="termsAndConditionsAccepted"
                options={termsAndConditionsOptions}
                fieldError={errors.termsAndConditionsAccepted}
              ></CheckBoxGroup>
            </div>
            <div className={styles.actions}>
              <DsmButton disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
                <DsmIcon name="communication/send-01"></DsmIcon>
                {t("support-form.submit")}
              </DsmButton>
            </div>
          </>
        ) : (
          <div className={styles.success}>{t("support-form.success-message")}</div>
        )
      ) : (
        <DsmLoadingIndicator className="loading-indicator" size="md"></DsmLoadingIndicator>
      )}
    </DsmGrid>
  );
}

export default SupportForm;
