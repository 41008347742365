export enum SupportRegion {
  EMEA = "EMEA",
  LATAM = "LATAM",
  GAPAC = "GAPAC",
  Global = "Global"
}

export enum SupportCategory {
  Functional = "Functional",
  Suggestion = "Suggestion",
  Feedback = "Feedback",
  Other = "Other"
}

export enum SupportTechnicalItem {
  Technical = "Technical",
  Login = "Login",
  Notification = "Notification"
}

export enum SupportSuggestionItem {
  Design = "Design",
  Functionality = "Functionality",
  Translation = "Translation"
}
