import { DsmHeader, DsmHeaderAction, DsmIcon } from "@dsm-dcs/design-system-react";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { routes } from "../../routes";
import { useTranslation } from "react-i18next";
import useBreakpoint, { Breakpoints } from "../../hooks/breakpoint.hook";
import styles from "./Header.module.scss";
import NotificationList from "../notificationList/NotificationList";
import HeaderMenu from "./HeaderMenu";
import { Notification } from "../../models/API";
import { getNotifications } from "../../services/user.service";
import { IToast } from "../../models/toast.model";
import { AuthContext } from "../../contexts/auth.context";
import { Role } from "../../models/enums/role.enum";

type Props = {
  title: string;
  setToast: Dispatch<SetStateAction<IToast | null>>;
};

function Header({ title, setToast }: Props) {
  //Hooks
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const location = useLocation();
  const { customer, role } = useContext(AuthContext);

  //State
  const [shouldShowNotifications, setShouldShowNotifications] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    loadNotifications();
  }, [location, customer]); //Also use customer as dependency, because header is not dependent on isLoading from AuthContext

  //Methods
  const loadNotifications = async () => {
    if (role === Role.Customer && customer) {
      setNotifications(await getNotifications(customer.id || "", setToast));
    }
  };

  return (
    <>
      {breakpoint !== Breakpoints.xs ? (
        <DsmHeader>
          <NavLink slot="navigation" to={routes.home} className={({ isActive }) => (isActive ? "active" : "")}>
            {t("header.navigation.home")}
          </NavLink>
          {role === Role.Customer ? (
            <NavLink slot="navigation" to={routes.farms} className={({ isActive }) => (isActive ? "active" : "")}>
              {t("header.navigation.farms")}
            </NavLink>
          ) : null}
          {role === Role.Manager || role === Role.Admin ? (
            <NavLink slot="navigation" to={routes.customers} className={({ isActive }) => (isActive ? "active" : "")}>
              {t("header.navigation.customers")}
            </NavLink>
          ) : null}
          <NavLink slot="navigation" to={routes.dashboard} className={({ isActive }) => (isActive ? "active" : "")}>
            {t("header.navigation.dashboard")}
          </NavLink>
          <NavLink slot="navigation" to={routes.support} className={({ isActive }) => (isActive ? "active" : "")}>
            {t("header.navigation.support")}
          </NavLink>
          {role === Role.Customer ? (
            <DsmHeaderAction
              slot="actions"
              icon="alerts-feedback/bell-01"
              description={t("notifications.header")}
              has-alert={notifications.length !== 0}
            >
              <div slot="dropdown">
                <NotificationList notifications={notifications} setToast={setToast}></NotificationList>
              </div>
            </DsmHeaderAction>
          ) : null}
          <div slot="actions">
            <HeaderMenu></HeaderMenu>
          </div>
        </DsmHeader>
      ) : (
        <>
          <div id="mobile-header" className={styles.container}>
            <DsmIcon
              name="alerts-feedback/bell-01"
              size="md"
              onClick={() => setShouldShowNotifications(true)}
              has-alert={notifications.length !== 0}
              role="button"
              aria-label={t("notifications.header")}
            ></DsmIcon>
            <h2 className={styles.title}>{title}</h2>
            <HeaderMenu></HeaderMenu>
          </div>
          {shouldShowNotifications ? (
            <NotificationList notifications={notifications} setShow={setShouldShowNotifications} setToast={setToast}></NotificationList>
          ) : null}
        </>
      )}
    </>
  );
}

export default Header;
