export const routes = {
  home: "/",
  farms: "/farms",
  farmCreate: "/farms/create",
  farmSamples: "/farms/:locationId/samples",
  farmSample: "/farms/sample/:sampleID",
  dashboard: "/dashboard",
  customers: "/customers",
  samples: "/samples",
  sampleRequestCreate: "/sample-request/create",
  order: "/order",
  orderStatus: "/order-status",
  support: "/support",
  supportForm: "/support-form"
};

export const routeTypes = {
  home: "home",
  farms: "farms",
  farmCreate: "farm-create",
  farmSamples: "samples",
  farmSample: "sample",
  dashboard: "dashboard",
  customers: "customers",
  customerSelected: "customersSelected",
  samples: "samples",
  sampleRequestCreate: "sample-request-create",
  order: "order",
  orders: "orders",
  support: "support",
  supportForm: "support-form",
  loading: "loading",
  error: "error"
};
