import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useBreakpoint, { Breakpoints } from "../../hooks/breakpoint.hook";
import { AuthContext } from "../../contexts/auth.context";
import { IFooterLink } from "../../models/footerLink.model";
import { DsmFooter, DsmFooterNav, DsmFooterNavigation, DsmIcon } from "@dsm-dcs/design-system-react";
import { NavLink } from "react-router-dom";
import { routes } from "../../routes";
import { Role } from "../../models/enums/role.enum";

function Footer() {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const { isAuthorized, role } = useContext(AuthContext);
  const [footerLinks, setFooterLinks] = useState<IFooterLink[]>([]);

  useEffect(() => {
    setFooterLinks(t("footer.navigation", { returnObjects: true }));
  }, []);

  const openCookieSettings = (): void => {
    document.querySelector(".cookiepolicy")?.querySelector("a")?.click();
  };

  return (
    <>
      {" "}
      {breakpoint !== Breakpoints.xs ? (
        <DsmFooter variant="minimal">
          <DsmFooterNav slot="banner-navigation">
            {footerLinks.map((link) => (
              <a href={link.url} target="_blank" rel="noreferrer" key={link.label}>
                {link.label}
              </a>
            ))}
            {window.truste ? (
              <a role="button" tabIndex={0} onClick={openCookieSettings}>
                {t("footer.cookie-settings")}
              </a>
            ) : null}
          </DsmFooterNav>
        </DsmFooter>
      ) : isAuthorized ? (
        <DsmFooterNavigation>
          <NavLink slot="navigation" to={routes.home} className={({ isActive }) => (isActive ? "active" : "")}>
            <DsmIcon name="general/home-line" size="md"></DsmIcon>
            {t("header.navigation.home")}
          </NavLink>
          {role === Role.Customer ? (
            <NavLink slot="navigation" to={routes.farms} className={({ isActive }) => (isActive ? "active" : "")}>
              <DsmIcon name="maps-travel/map-02" size="md"></DsmIcon>
              {t("header.navigation.farms")}
            </NavLink>
          ) : null}
          {role === Role.Manager || role === Role.Admin ? (
            <NavLink slot="navigation" to={routes.customers} className={({ isActive }) => (isActive ? "active" : "")}>
              <DsmIcon name="users/user-02" size="md"></DsmIcon>
              {t("header.navigation.customers")}
            </NavLink>
          ) : null}
          <NavLink slot="navigation" to={routes.dashboard} className={({ isActive }) => (isActive ? "active" : "")}>
            <DsmIcon name="charts/line-chart-up-04" size="md"></DsmIcon>
            {t("header.navigation.dashboard")}
          </NavLink>
          <NavLink slot="navigation" to={routes.support} className={({ isActive }) => (isActive ? "active" : "")}>
            <DsmIcon name="education/book-open-02" size="md"></DsmIcon>
            {t("header.navigation.support")}
          </NavLink>
        </DsmFooterNavigation>
      ) : null}
    </>
  );
}

export default Footer;
