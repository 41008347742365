import * as yup from "yup";
import i18next from "i18next";
import { ComparisonType } from "../API";

export interface FilterSampleRequestForm {
  searchQuery: string;
  collectionDateStart: string;
  collectionDateEnd: string;
  phaseIds: string;
  results: ResultsFilterOption;
  resultsType: ComparisonType;
  resultsValue: number;
}

export enum ResultsFilterOption {
  ResultsPending = "ResultsPending",
  HasResults = "HasResults"
}

export const getFilterSampleRequestFormSchema = () => {
  return yup
    .object({
      searchQuery: yup.string().optional().label(i18next.t("general.search")),
      collectionDateStart: yup.string().optional().label(i18next.t("sample-request.collection-date-start")),
      collectionDateEnd: yup.string().optional().label(i18next.t("sample-request.collection-date-end")),
      phaseIds: yup.string().optional().label(i18next.t("sample-request.phase")),
      results: yup.mixed<ResultsFilterOption>().optional().label(i18next.t("sample-request.average")),
      resultsType: yup.mixed<ComparisonType>().optional().label(i18next.t("sample-request.results-type")),
      resultsValue: yup.number().optional().positive().label(i18next.t("sample-request.results-value"))
    })
    .required();
};
