import { DsmCheckbox, DsmCheckboxGroup } from "@dsm-dcs/design-system-react";
import { isRequired } from "../../../helpers/validation.helper";
import { Control, Controller, FieldError } from "react-hook-form";
import { AnyObject, ObjectSchema } from "yup";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  fieldName: string;
  label?: string;
  tooltip?: string;
  options: string[];
  schema?: ObjectSchema<AnyObject>;
  fieldError?: FieldError;
  forceRequired?: boolean;
  disabled?: boolean;
};

function CheckBoxGroup({ control, fieldName, label, tooltip, options, schema, fieldError, forceRequired, disabled }: Props) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <DsmCheckboxGroup
          data-testid={`check-box-group-${fieldName}`}
          data-value={value} //TODO: Find better method to get value in tests
          direction="row"
          label={label}
          tooltip={tooltip}
          errorText={fieldError?.message}
          required={forceRequired || isRequired(schema, fieldName)}
          disabled={disabled}
        >
          {options.map((option) => (
            <DsmCheckbox
              onDsmChange={(e) => {
                onChange(e.detail);
                onBlur();
              }}
              label={option}
              key={option}
            ></DsmCheckbox>
          ))}
        </DsmCheckboxGroup>
      )}
    />
  );
}

export default CheckBoxGroup;
