import * as yup from "yup";
import i18next from "i18next";
import { SupportCategory, SupportRegion, SupportSuggestionItem, SupportTechnicalItem } from "../enums/supportForm.enum";

export interface SupportFormForm {
  firstName: string;
  lastName: string;
  country: string;
  email: string;
  region: SupportRegion;
  category: SupportCategory;
  technicalItem?: SupportTechnicalItem;
  suggestion?: SupportSuggestionItem;
  description: string;
  url?: string;
  termsAndConditionsAccepted: boolean;
}

export const getSupportFormFormSchema = () => {
  return yup
    .object({
      firstName: yup.string().required().label(i18next.t("support-form.first-name")),
      lastName: yup.string().required().label(i18next.t("support-form.last-name")),
      country: yup.string().required().label(i18next.t("support-form.country")),
      email: yup.string().required().label(i18next.t("support-form.email")),
      region: yup.mixed<SupportRegion>().oneOf(Object.values(SupportRegion)).required().label(i18next.t("support-form.region")),
      category: yup.mixed<SupportCategory>().oneOf(Object.values(SupportCategory)).required().label(i18next.t("support-form.category")),
      technicalItem: yup
        .mixed<SupportTechnicalItem>()
        .oneOf(Object.values(SupportTechnicalItem))
        .when("category", {
          is: SupportCategory.Functional,
          then: (schema) => schema.required().label(i18next.t("support-form.technical-item"))
        }),
      suggestion: yup
        .mixed<SupportSuggestionItem>()
        .oneOf(Object.values(SupportSuggestionItem))
        .when("category", {
          is: SupportCategory.Suggestion,
          then: (schema) => schema.required().label(i18next.t("support-form.suggestion"))
        }),
      description: yup.string().required().label(i18next.t("general.this")),
      url: yup.string().optional().label(i18next.t("support-form.url")),
      termsAndConditionsAccepted: yup.boolean().required().oneOf([true], i18next.t("support-form.accept-terms-and-conditions"))
    })
    .required();
};
