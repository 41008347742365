import ReactDOM from "react-dom/client";
import "./i18n";
import "@dsm-dcs/design-system/dist/dsm-design-system/dsm-design-system.css";
import "./index.scss";
import Layout from "./layouts/Layout";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/other/home/Home";
import FarmList from "./pages/farm/list/FarmList";
import Error from "./pages/other/error/Error";
import FarmCreate from "./pages/farm/create/FarmCreate";
import { routes } from "./routes";
import RequireAuth from "./components/requireAuth/RequireAuth";
import { Amplify } from "aws-amplify";
import SampleRequestCreate from "./pages/sampleRequest/create/Create";
import SampleRequestList from "./pages/sampleRequest/list/SampleRequestList";
import FarmSampleRequestList from "./pages/sampleRequest/list/FarmSampleRequestList";
import SampleRequestRead from "./pages/sampleRequest/read/SampleRequestRead";
import KitRequestCreate from "./pages/kitRequest/create/KitRequestCreate";
import OrderHistory from "./pages/kitRequest/orderHistory/OrderHistory";
import { getAwsConfig } from "./helpers/config.helper";
import SSOLogin from "./pages/sso/SSOLogin";
import SSOLogout from "./pages/sso/SSOLogout";
import SSOProxy from "./pages/sso/SSOProxy";
import Support from "./pages/other/support/Support";
import Customers from "./pages/customer/Customers";
import { Role } from "./models/enums/role.enum";
import * as yup from "yup";
import SupportForm from "./pages/other/support-form/SupportForm";
import Dashboard from "./pages/dashboard/Dashboard";

Amplify.configure(getAwsConfig());

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).digitalData = {
  page: {
    pageInfo: {},
    user: {
      profileInfo: {}
    }
  }
};

//window.print() does not trigger the beforeprint event, so we need to override print and fire the event manually
const print = window.print;
window.print = () => {
  window.dispatchEvent(new Event("beforeprint"));
  setTimeout(() => {
    print();
  }, 0);
};

yup.addMethod(yup.array, "uniqueProperty", function (message, path, propertyName) {
  return this.test("uniqueProperty", message, function (list) {
    if (!list) {
      return true;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mapper = (a: any) => a[propertyName];
    const set = Array.from(new Set(list.map(mapper)));
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({ path: `${path}.${[idx]}.${propertyName}`, message });
  });
});

const router = createBrowserRouter([
  {
    element: <SSOProxy />,
    children: [
      {
        path: "/sso-proxy"
      }
    ]
  },
  {
    element: <SSOLogin />,
    children: [
      {
        path: "/sso-login"
      }
    ]
  },
  {
    element: <SSOLogout />,
    children: [
      {
        path: "/sso-logout"
      }
    ]
  },
  {
    element: <Layout />,
    children: [
      {
        path: "*",
        element: <Error />
      },
      {
        path: routes.home,
        element: (
          <RequireAuth>
            <Home />
          </RequireAuth>
        )
      },
      {
        path: routes.farms,
        element: (
          <RequireAuth roles={[Role.Customer]}>
            <FarmList />
          </RequireAuth>
        )
      },
      {
        path: routes.farmCreate,
        element: (
          <RequireAuth>
            <FarmCreate />
          </RequireAuth>
        )
      },
      {
        path: routes.farmSamples,
        element: (
          <RequireAuth customerRequiredForManager={true}>
            <FarmSampleRequestList />
          </RequireAuth>
        )
      },
      {
        path: routes.farmSample,
        element: (
          <RequireAuth customerRequiredForManager={true}>
            <SampleRequestRead />
          </RequireAuth>
        )
      },
      {
        path: routes.customers,
        element: (
          <RequireAuth roles={[Role.Manager, Role.Admin]}>
            <Customers />
          </RequireAuth>
        )
      },
      {
        path: routes.samples,
        element: (
          <RequireAuth>
            <SampleRequestList />
          </RequireAuth>
        )
      },
      {
        path: routes.dashboard,
        element: (
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        )
      },
      {
        path: routes.sampleRequestCreate,
        element: (
          <RequireAuth>
            <SampleRequestCreate />
          </RequireAuth>
        )
      },
      {
        path: routes.order,
        element: (
          <RequireAuth roles={[Role.Admin]}>
            <KitRequestCreate />
          </RequireAuth>
        )
      },
      {
        path: routes.orderStatus,
        element: (
          <RequireAuth>
            <OrderHistory />
          </RequireAuth>
        )
      },
      {
        path: routes.support,
        element: (
          <RequireAuth>
            <Support />
          </RequireAuth>
        )
      },
      {
        path: routes.supportForm,
        element: (
          <RequireAuth>
            <SupportForm />
          </RequireAuth>
        )
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<RouterProvider router={router} />);
